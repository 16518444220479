<template>
  <slot v-if="isLoading" name="loading-state" />
  <template v-else-if="isError || capturedObject.ocurred">
    <slot
      v-if="!errorCase || errorCase === 'unknown'"
      name="error-state"
      v-bind="capturedObject"
      :info="capturedObject.info || errorMessage || ''">
      <div :class="props.class">
        <strong class="text-base font-semibold leading-6 text-slate-900">에러가 발생했습니다.</strong>
        <p v-if="errorMessage" class="text-sm font-normal leading-5 text-slate-600">
          {{ errorMessage }}
        </p>
      </div>
    </slot>
    <slot v-else :name="`${errorCase}-error-state`" v-bind="capturedObject" />
  </template>
  <slot v-else-if="isEmpty" name="empty-state">
    <div :class="props.class">
      <strong class="text-base font-semibold leading-6 text-slate-900">{{ emptyState?.title }}</strong>
      <p v-if="emptyState?.description" class="text-sm font-normal leading-5 text-slate-600">
        {{ emptyState.description }}
      </p>
    </div>
  </slot>
  <slot v-else />
</template>

<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue'
import type { FallbackEmits, FallbackProps } from '~/components/ui/fallback/types'

const props = withDefaults(defineProps<FallbackProps>(), {
  isLoading: undefined
})

const emit = defineEmits<FallbackEmits>()

const capturedObject = reactive<{
  error: Error
  instance: ComponentPublicInstance | null
  info: string
  ocurred: boolean
}>({
  error: new Error('error'),
  instance: null,
  info: '',
  ocurred: false
})
//
// onErrorCaptured((error, instance, info) => {
//   capturedObject.ocurred = true
//   capturedObject.error = error
//   capturedObject.instance = instance
//   if (!props.errorMessage) capturedObject.info = info
//
//   emit('error', {
//     error,
//     instance,
//     info
//   })
//   return true
// })
</script>
